<template>
  <v-container align-center justify-center>
    <v-layout row wrap justify-center>
      <v-img src="@/assets/images/logo.jpg" max-height="190" max-width="150">
      </v-img>
    </v-layout>
    <v-layout row wrap>
      <v-card class="mx-auto" color="blue darken-4" dark max-width="800">
        <v-card-title>
          <v-icon large left> fab fa-wpforms </v-icon>
          <span class="title">Policies/Politicas</span>
        </v-card-title>

        <v-card-text class="light headline font-weight-bold text-xs-center">
          <p>
            BY FILLING OUT AND SIGNING THIS FORM YOU AGREE, AND ARE HELD
            RESPONSIBLE FOR FOLLOWING THE RULES ABOVE FOR THE OPERATION OF THIS
            WAREHOUSE, SET FORTH BY ORIGIN WAREHOUSE SERVICES
          </p>
          <p>
            Llenando esta forma y firmandola acepto mi responsabilidad en el
            seguimiento de las reglas de operacion de este almacen. Indicadas
            por ORIGIN WAREHOUSE SERVICES
          </p>
        </v-card-text>

        <v-card-actions class="align-center justify-center">
          <v-btn color="blue" dark @click="accept"
            >Accept/Acepto
            <v-icon right>mdi-account-check-outline </v-icon>
          </v-btn>
          <v-btn color="red" dark @click="decline"
            >Decline/No
            <v-icon right>mdi-cancel</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
const timeout = 1000 * 15; // 10 segundos
let timer = null;
export default {
  name: "policies",
  data() {
    return {
      type: null,
    };
  },
  created() {
    this.type = this.$route.params.type;
    if (!this.type) {
      this.decline();
    }
    timer = setTimeout(() => this.decline(), timeout);
  },
  methods: {
    accept() {
      clearTimeout(timer);
      this.$router.push({
        path: `/shipping/checkin/${this.type}`,
      });
    },
    decline() {
      clearTimeout(timer);
      this.$router.push({
        path: "/shipping/checkin/type",
      });
    },
  },
};
</script>

<style>
.action {
  height: 90px;
  font-size: 1.3em;
}

.light {
  color: white !important;
}
</style>
